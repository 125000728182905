.productlist {
    &-usps {
        background: $secondary-color;
        padding-top: .75rem;
        padding-bottom: .7rem;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            margin-left: calc(var(--bs-gutter-x) * .5);
            margin-right: calc(var(--bs-gutter-x) * .5);
        }
    
        &__row {
            display: flex;
            width: 400vw;
            justify-content: auto;
            animation: slide-in-out 16s ease-out infinite both;
            -webkit-animation: slide-in-out 16s ease-out infinite both;
            -moz-animation: slide-in-out 16s ease-out infinite both;
            -o-animation: slide-in-out 16s ease-out infinite both;
        }

        &__item {
            width: 100vw;
            display: inline-flex;
            justify-content: center;
            vertical-align: middle;
        }

        @keyframes slide-in-out {
            0%, 15% {
                transform: translateX(0);
            }
            29%, 49% {
                transform: translateX(-25%);
            }
            62%, 82% {
                transform: translateX(-50%);
            }
            95%, 100% {
                transform: translateX(-75%);
            }
        }

        @include media-breakpoint-up(md) {
            &__row {
                width: 100%;
                justify-content: space-around;
                -webkit-animation: none;
                -moz-animation: none;
                -o-animation: none;
                animation: none;
            }
            &__item {
                width: auto;
            }
        }
    
        &__icon svg{
            vertical-align: middle!important;
        }
    
        &__text {
            color: $white;
            font-size: map-get($fs,200);
            font-weight: map-get($fw,600);
        }
    }

    &__count {
        line-height: 2.75rem;
        vertical-align: middle;
    }

    &__sort {
        width: 100%;
        max-width: 150px;
        outline: 0;
    }

    &__login {
        background: $white;
        border-radius: 1rem;
        box-shadow: 0px 0px .5rem rgba(0, 0, 0, 0.2);
        padding: 1.5rem 1.5rem 2.5rem;
    }  

    &__sidebar {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }
}