.img {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    vertical-align: bottom;

    &__fluid {
        width: 75%;
        margin: 0 auto;
        display: table;
    }

    > img {
        @include transition;
        display: inline-block;
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        object-position: center;
        vertical-align: bottom;
    }

    &--auto {
        width: auto;
        max-width: 100%;

        img {
            width: auto;
            max-width: 100%;
        }

        &.img--absolute.img--l {
            right: initial;
        }

        &.img--absolute.img--r {
            left: initial;
        }
    }

    &--contain {
        > img {
            object-fit: contain;
        }
    }

    &--fill {
        > img {
            object-fit: fill;
        }
    }

    &--cover {
        > img {
            object-fit: cover;
        }
    }

    &--t {
        img {
            object-position: top center;
        }
    }

    &--r {
        img {
            object-position: center right;
        }
    }

    &--b {
        img {
            object-position: bottom center;
        }
    }

    &--l {
        img {
            object-position: center left;
        }
    }

    &--tl {
        img {
            object-position: top left;
        }
    }

    &--absolute {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &--overlay {
        &:after {
            @include transition;
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($black, .5);
        }
    }
}
