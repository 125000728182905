.pagination {
    &__nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;

        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
        }
    }

    &__nav-item {
        cursor: pointer;
        position: relative;
        display: block;
        width: rem(30);
        height: rem(30);
        border: 1px solid $secondary-color-light;
        border-radius: 100%;
        background: $white;

        span {
            font-size: map-get($fs,200);
            color: $secondary-color;
        }

        @include media-breakpoint-up(lg) {
            width: rem(50);
            height: rem(50);
        }

        + .pagination__nav-item {
            margin-left: .2rem;

            @include media-breakpoint-up(lg) {
                margin-left: .5rem;
            }
        }

        &--separator {
            cursor: default;
            border: none;
            width: 1rem;
        }

        &--current {
            cursor: default;
            border-color: $primary-color;
            background: $primary-color;
        
            span {
                color: $white;
            }
        }

        span, svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        span {
            @extend .text;
        }

        svg {
            width: rem(12.5);
            height: rem(10);

            path {
                fill: $primary-color;
            }
        }
    }
}
