.alert {
    padding: 1rem;
    color: $white;
    font-weight: map-get($fw,800);
    font-size: map-get($fs,200);
    border-radius: .25rem;
    text-align: center;
    margin-bottom: 2rem;

    &--hide {
        display: none;
    }
    
    &-danger {
        background: $error-color;
    }
    &-success {
        background: $secondary-color;
    } 

    &-info {
        background: $secondary-color-light;
        color: $secondary-color;
    }
}