.searchbar {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 40rem;
    height: 3rem;
    padding: .25rem;
    border: 1px solid $secondary-color-light;
    border-radius: 1.5rem;
    font-family: $font-family;
    font-size: map-get($fs, 300);
    font-weight: map-get($fw, 400);

    @include media-breakpoint-up(lg) {
        width: rem(370);
    }

    &__input {
        width: 250rem;
        padding: 0 1.25rem 0 1rem;
        color: $text-color;
        border: none;
        border-radius: 1.5rem 0 0 1.5rem !important;
        box-shadow: none;
        outline: none;
        font: inherit;
    }

    &__submit {
        padding: rem(11);
        margin-left: auto;
    }
}
