.price {
    font-size: map-get($fs, 600);
    font-weight: map-get($fw, 800);
    
    &__text {
        color: $text-color-light; 
        font-size: map-get($fs, 100);
        font-weight: map-get($fw, 200);
    }
}
