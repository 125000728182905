.cart {
    &__title {
        font-size: map-get($fs,800);
    }
    &__shipping {
        &-title {
            font-size: map-get($fs,300);
        }
        &-method {
            width: 100%;
            display: block;
            background: $white;
            border: 1px solid $secondary-color-light;
            padding: 1rem 1rem 1rem 2.6rem;
            border-radius: 4px;
            margin-bottom: 1.2rem;
            font-size: map-get($fs,200);

            &__title {
                font-weight: map-get($fw, 600);
            }
    
            &__description {
                font-size: map-get($fs,100);
                display: block;
                margin-top: .5rem;
            }

            &__costs {
                color: $secondary-color;
                float: right;
                font-weight: map-get($fw, 600);
            }
            transition: .3s;

            &:hover {
                background: $secondary-color-lighter;
            }
        }
    }
    &__loading {
        opacity: 0.5;
        transition: .2s;
        pointer-events: none;
    }
}
