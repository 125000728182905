.text {
    color: $text-color;
    font-family: $font-family;
    font-size: map-get($fs, 200);
    font-weight: map-get($fw, 400);
    line-height: 1.5rem;

    &--light {
        color: $text-color-light!important;
    }

    strong {
        font-weight: map-get($fw, 600);
    }
    &--strong {
        font-weight: map-get($fw, 600);
    }

    &--danger {
        color: $error-color;
    }

    &--left {
        text-align: left;
    }

    &--secondary-color {
        color: $secondary-color;
    }
}