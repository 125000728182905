.notification-icon {
    background: $primary-color;
    height: 1.6rem;
    width: 1.6rem;
    border-radius: 100%;
    position: absolute;
    top: -.8rem;
    right: -.8rem;
    line-height: 1.5rem;
    vertical-align: middle;
    color: $white;
    font-weight: 300;
    font-size: 0.875;

    &__number {
        margin: 0 auto;
        display: table;
        text-align: center;
    }
}