.webshop-description {
    li {
        list-style: unset;
    }

    h1, h2, h3, h4, h5, h6 {
        font-size: map-get($fs, 500);
        font-weight: map-get($fw, 800);
        margin-bottom: 1rem;
    }
}