.microcart {
    position: fixed;
    top: 6.25rem;
    display: none;
    background: $white;
    padding: .5rem 1.5rem 1.5rem;
    border: 1px solid $secondary-color-light;
    border-radius: .5rem;
    min-width: 20rem;

    &__heading {
        font-size: map-get($fs, 500);
    }

    &__product-image img {
        width: 100%;
        max-width: 5rem;
    }
    &__product {
        display: flex;
        align-items: center;

        &__title {
            width: 15rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    &__button {
        transition: unset;
    }
    &__close {
        cursor: pointer;

        svg {
            color: $primary-color;
            width: 1rem;
            position: absolute;
            right: 1.25rem;
            top: .75rem;
        }
    }
}
