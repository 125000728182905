.nav {

    @include media-breakpoint-down(lg) {
        display: none;
    }

    @include media-breakpoint-down(lg) {
        display: none;
        position: fixed;
        top: 141px;
        width: 100%;
        bottom: 0;
        background: $white;
        border-top: 1px solid $secondary-color-light;
        border-bottom: 1px solid $secondary-color-light;
        padding: 0;

        &__divider {
            border: none;
            height: 1px;
            background: $secondary-color-light;
        }

        .link {
            display: block;
            margin: 1rem auto 1rem;
            font-size: map-get($fs,200)!important;
            font-weight: map-get($fw,200)!important;
            padding-left: 1rem;            
        }
    }

    &__account-icon {
        svg {
            height: 14px;
            line-height: 14px;
            vertical-align: middle;
            margin-left: .25rem;
        }
    }

    &__account-menu {
        display: none;
        position: absolute;
        background: $white;
        padding: 1rem 1.5rem;
        margin-top: 1.5rem;
        margin-left: 6.5rem;
        border: 1px solid $secondary-color-light;
        border-radius: .5rem;

        li {
            margin-bottom: .5rem;
        }
    }
}