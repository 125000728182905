.number {
    position: relative;
    display: inline-block;
    border-radius: rem(8);
    overflow: hidden;
    border: rem(1) solid $gray-400;
    height: rem(45);
    background: $white;

    &__field {
        appearance: textfield;
        width: rem(45);
        height: rem(45);
        border: solid $gray-400;
        border-width: 0;
        font-family: $font-family;
        text-align: center;
        font-size: map-get($fs,300);

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    &__btn {
        cursor: pointer;
        position: relative;
        width: rem(30);
        height: rem(40);
        background: $white;

        svg > path{
            transition: .3s;
        }
        
        &:hover svg > path {
            color: $primary-color!important;
            fill: $primary-color!important;
        }

        @include media-breakpoint-up(lg) {
            width: rem(30);
            height: rem(45);
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: rem(10);
            height: rem(10);
            transform: translate(-50%, -50%);

            @include media-breakpoint-up(lg) {
                width: rem(12);
                height: rem(12);
            }

            path {
                fill: $placeholder-color;
            }
        }
    }
}
