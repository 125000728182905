.header {
    @include z-index(header);
    position: sticky;
    top: 0;
    height: $header-height;
    background: $white;
    z-index: 12;

    @include media-breakpoint-up(lg) {
        height: $header-height-lg;
    }

    &__logo {
        width: rem(89);
        height: rem(45);

        @include media-breakpoint-up(lg) {
            width: rem(140);
            height: rem(71);
        }

        &--cart {
            height: rem(35);
            margin-top: rem(17.5);

            @include media-breakpoint-up(lg) {
                height: 3.75rem;
                margin-top: 1.25rem;
            }
        }
    }

    &__search {
        .input__group {
            height: 3rem;
            padding: rem(15) rem(20);
            border-radius: 1.5rem;
        }
    }

    &__link {
        border-right: 1px solid $secondary-color-light;
    }
}
.overlay {
    position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
    display: none;
}
