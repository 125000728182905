.productdetails {
    &__slidernav {
        &__container {
            width: 95%;
            margin-top: 1.5rem;
        }

        .slick-track {
            cursor: pointer;
            margin: 0;

            .slick-slide {
                margin-right: 25px;
            }
        }
    }

    &__lightbox {
        &__prev, &__next {
            position: fixed;
            top: 50%;
            margin-top: -1.125rem;
            z-index: 10000;
        }

        &__prev {
            left: 0;
        }
        &__next {
            right: 0;
        }
    }

    &__slider-thumbnail {
        width: 100px;
        height: 100px;
        margin-right: 3rem;
        border: 1px solid $placeholder-color;

        &.slick-active {
            border: 1px solid $text-color-light;
        }
    }

    &__slider {
        position: relative;
        width: 100%;
        height: rem(325);
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            height: rem(550);
        }

        * {
            height: 100% !important;
        }

        &__prev, &__next {
            cursor: pointer;

            svg {
                color: $primary-color;
            }
        }
    }

    &__table {
        text-align: left;

        th {
            padding: 0 2rem 0 0;
        }
    }

    &__features {
        &__option {
            cursor: pointer;
        }

        &__label {
            cursor: pointer;
            display: inline-flex;
            align-items: center;
        }
        &__title {
            font-weight: map-get($fw ,600);
        }
        &__radio-span {
            top: unset;
            margin-top: unset;
            margin-left: 0;
            left: unset;
        }
        &__option-title {
            margin-left: 1.5rem;
        }
    }


    &__container-table {
        overflow: hidden;

        &--open {
            height: unset;
            overflow: unset;
        }
    }
    &__stock {
        color: $success-color;
        font-weight: map-get($fw, 600);
        font-size: map-get($fs, 200);

        .green {
            color: $success-color;
        }
        .orange {
            color: $warning-color;
        }
    }

    &__delivery {
        &-title {
            background: $secondary-color;
            color: $white;
            text-align: center;
            font-size: map-get($fs, 200);
            font-weight: map-get($fw, 600);
        }
        &-text {
            font-size: map-get($fs, 100);
            background: $white;
        }
    }

    &__login {
        background: $white;
        border-radius: 1rem;
        padding: 1.5rem 1.5rem  2rem;
        box-shadow: 0 0 .5rem rgba(0,0,0,.2);
        max-width: 31.25rem;
    }
}
