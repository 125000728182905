.wishlistblock {    
    &__container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }

    &__summary {
        border: 1px solid $secondary-color-light;
        background: $white;
        border-radius: .5rem;
    
        &:hover {
            cursor: pointer;
            background: $secondary-color-lighter;
            transition: .2s;
        }
    
        &__icon {
            flex-direction: column;

            svg {
                transform: scale(1.2);
                vertical-align: middle;
            }
        }
    
        &__title {
            font-weight: map-get($fw, 600);
            color: $secondary-color;
            word-break: break-all;
        }
        &__arrow svg {
            transform: scale(0.5);
            flex-direction: column;
            justify-content: center;
            vertical-align: middle;
            height: 100%;
            float: right;
                    
            path {
                fill: $secondary-color-light;
            }
        }
        &__count {
            color: $text-color-light;
            font-size: map-get($fs, 100);
        }
    }
    
    &__open {
        display: none;

        &__nameform {
            display: none;        
        }
    }
}
