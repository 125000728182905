.productblock {
    display: grid;
    grid-template-rows: minmax(0, 1fr) auto;
    background: $white;
    border: 1px solid $secondary-color-light;
    border-radius: 1rem;

    &__title {
        color: $secondary-color;
        font-size: map-get($fs, 300);
        font-weight: map-get($fw, 800);
    }

    &__list {
        color: $text-color-light;
        font-size: map-get($fs, 200);
        margin: 1rem 0;

        li {
            margin: .5rem auto;
        }
    }

    &__delivery {
        font-size: map-get($fs, 200);

        .green {
            color: $success-color;
        }

        .orange {
            color: $warning-color;
        }
    }

    &__wishlist {

        svg {
            cursor: pointer;
        }

        &__added svg {
            transform: scale(.69);
        }

        button {
            transform: scale(1.5);
        }
    }
}
