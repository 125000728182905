.customer {

    &__menu {
        &-title {
            @include media-breakpoint-down(lg) {
                color: $secondary-color;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        &--active a {
            font-weight: map-get($fw, 800);
            color: $secondary-color;
        }
    }

    &__account {
        &-title {
            font-weight: map-get($fw, 800);
            font-size: map-get($fs, 800);
        }

        &__edit-password {
            display: none;
        }

        &-info {
            li {
                margin-bottom: .46875rem;
                font-size: map-get($fs,200);
            }
        }

        &-details {
            font-size: map-get($fs,200);

            th {
                text-align: left;
                padding: 0 4rem 0 0;
                padding-bottom: .46875rem;
            }
        }        

        &__menu {
            margin-bottom: 1rem!important;

            @include media-breakpoint-down(lg) {
                display: none;
            }
            a {
                transition: .3s;
            }
            a:hover {
                color: $secondary-color!important;
            }
        }
    }

    &__input-group {
        margin: 0 auto;
        display: table;
        width: 100%;
    }   
}