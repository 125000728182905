.input {
    position: relative;
    display: block;

    &__label {
        display: block;
        margin-bottom: .25rem;
        font-size: map-get($fs, 200);
        font-weight: map-get($fw, 600);
    }

    &__required {
        color: $primary-color;
    }

    &__description {
        color: $text-color-light;
        font-size: map-get($fs, 100);
        font-weight: map-get($fw, 600);
    }

    &__field {
        outline: unset;
        background: $white;
        border: .0625rem solid $secondary-color-light!important;
        padding: .5625rem;
        font-size: map-get($fs, 200);
        width: 100%;
        border-radius: .25rem!important;
        font-family: $font-family;

        &[type="radio"] {
            display: none;

            &:checked {
                ~ .input__radio-field {
                    border-width: 5px;
                }
            }
        }
    }

    &--disabled {
        background: rgba(196, 196, 196, .2);
        cursor: not-allowed;
    }

    &__radio, &__checkbox {
        &__container {
            display: block;
            position: relative;
            cursor: pointer;
            font-size: 22px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        &__container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        &__span {
            position: absolute;
            top: 0;
            left: 0;
            height: 15px;
            width: 15px;
            background-color: $white;
            border-radius: 100%;
            border: 2px solid $primary-color;
            margin-top: 17.5px;
            margin-left: 13px;

            &--no-radius {
                border-radius: unset;
            }
        }

        &__container:hover input ~ .input__radio__span {
            background-color: $secondary-color-lighter;
        }

        &__container input:checked ~ .input__radio__span {
            background-color: $primary-color;
        }

        &__span:after {
            content: "";
            position: absolute;
            display: none;
        }

        &__container input:checked ~ .input__radio__span:after {
            display: block;
        }

        &__container .input__radio__span:after {
            left: 3px;
            top: 3px;
            width: 5px;
            height: 5px;
            background: #fff;
            border-radius: 100%;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &__container--no-radius .input__radio__span:after {
            border-radius: unset;
            transform: rotate(90deg);
            left: 0;
            top: 0;
            width: 0;
            height: 0;
        }
    }

    &__radio {
        cursor: pointer;
        display: inline-grid;
        grid-template-columns: auto minmax(0, 1fr);
        grid-gap: .5rem;
        align-items: center;
        height: 2.5rem;
        margin-right: 1rem;
    }

    &__radio-field {
        @include transition;
        width: 1rem;
        height: 1rem;
        border: 2px solid $primary-color;
        border-radius: 50%;
    }
}
