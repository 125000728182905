.filters {
    &__price-submit {
        
        &__btn {
            padding: 0.6875rem;

            svg {
                height: .8rem;
                color: $white;
            }
        }
    }

    &__inner {
        @include media-breakpoint-down(lg) {
            padding: 1rem;
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: $white;
            z-index: 10000000;
            box-shadow: 0 0 .5rem rgba(0,0,0,.5);
            background: $white;
        }
    }

    &__price-input {
        @include media-breakpoint-down(lg) {
            width: 20%;
        }
    }

    &__radio-button {
        margin: .5rem 0;
    }
    &__radio-text {
        margin-left: 2rem;
    }

    &__footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 1.5rem 0;
        box-shadow: 0 0 .5rem rgba(0,0,0,0.3);
    }
    &__price-icon svg {
        height: 1rem;
        color: $secondary-color;
        cursor: pointer;
        margin-top: .3rem;
    }
    &__remove-filter {
        padding-right: 0;

        svg {
            height: 15px;
            color: $danger;
            cursor: pointer;
            margin-top: .3rem;
        }
    }
    &__price-range {
        input {
            width: 100%;
        }
    }
    &__filter-list {
        height: 8.8rem;
        overflow: hidden;

        &--open {
            height: auto;
            overflow: unset;
        }
    }
    &__filter-icon {
        svg {
            height: 12px;
            vertical-align: middle;
        }
    }
    &__footer {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}