.checkout {
    &__heading {
        font-size: map-get($fs,'300');
        font-weight: map-get($fw, '700');
        margin-bottom: 0!important;
    }

    &__address-btn {
        background: $white;
        width: 100%;
        text-align: left;
        color: $secondary-color;
        font-size: map-get($fs,'200');
        border-radius: $border-radius;
        font-weight: map-get($fw, 400);
        padding: 0.6875rem .75rem;

        &:hover {
            background: $secondary-color-light;
        }

        svg {
            width: .5rem;
            margin-right: .3125rem;
        }
    }

    &__details {
        padding-left: 0;
        margin-bottom: 1rem;

        li {
            list-style-type: none;
        }
    }
    &__paymentMethods {
        padding-left: 0;
        
        li {
            list-style: none;
        }
    }

    &__payment-icons {
        vertical-align: middle;
        float: right;
        img {
            height: 1.5rem;
        }
    }

    &__summary {
        width: 100%;
    }

    &__shipping-fields {
        display: none;
    }
    
    &__shipping-method {
        width: 100%;
        display: block;
        background: $white;
        border: 1px solid $secondary-color-light;
        padding: 1rem;
        border-radius: 4px;
        margin-bottom: 1.2rem;
        transition: .3s;

        &:hover {
            background: $secondary-color-lighter;
        }

        &__title {
            font-size: map-get($fs,200);
            font-weight: map-get($fw, 600);
            padding-left: 1.5rem;
        }
        &__span {
            margin-top: 20px;
        }
    }
}