.btn {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: rem(9) 1rem;
    background: $primary-color;
    color: $white;
    border-radius: 1.25rem;
    font-size: map-get($fs, 200);
    font-weight: map-get($fw, 700);
    line-height: 1.125rem;
    transition: .3s;

    &:hover {
        background: darken($primary-color,5%);
    }

    @include media-breakpoint-up(lg) {
        padding: rem(11) 2rem;
    }

    &--text-white {
        color: $white!important;
        text-decoration: unset!important;
    }

    &--secondary {
        background: $secondary-color;

        &.btn--arrow {
            &:after {
                border-left-color: $secondary-color;
            }
        }
        &:hover {
            background: darken($secondary-color,5%);
        }
    }

    &--white {
        background: $white;
        color: $text-color-light;
        &:hover {
            background: darken($white,5%);
        }
    }

    &--custom-secondary {
        border-color: $secondary-color;
        color: $secondary-color;
        border: 1px solid $secondary-color;
        background: none;
        border-radius: 0;
    }

    &--arrow {
        padding-left: .75rem;
        padding-right: .25rem;
        border-radius: 0;
        box-shadow: 0 0 .5rem rgba($black, .25);

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -1rem;
            border: solid transparent;
            border-left-color: $primary-color;
            border-width: 1.25rem 0 1.25rem 1rem;
        }
    }

    &--success {
        background: $success-color!important; 
        transition: .3s;
    }

    &__loading {
        &-success {
            padding-right: .5rem;

            path {
                fill: $white;
            }
        }
    
        &-icon {
            vertical-align: middle;
            display: flex;
            width: 6.4rem;
            height: 18px;
            justify-content: center;
            
            svg {
                height: 1rem;

                -webkit-animation:loading 4s linear infinite;
                -moz-animation:loading 4s linear infinite;
                animation:loading 4s linear infinite;

                @-moz-keyframes loading { 
                    100% { -moz-transform: rotate(360deg); } 
                }
                @-webkit-keyframes loading { 
                    100% { -webkit-transform: rotate(360deg); } 
                }
                @keyframes loading { 
                    100% { 
                        -webkit-transform: rotate(360deg); 
                        transform:rotate(360deg); 
                    } 
                }
            }  
        }
    }

    &--small {
        padding: .7rem 1.2rem;
    }

    &__icon {
        background: none;
        color: $black;
        border: 1px solid $secondary-color-light;
        border-radius: .5rem;
        padding: .8rem;

        &__orange {
            border-color: $primary-color;
        }
        &:hover {
            background: darken($white,5%);
        }
    }
}
