.dashboard {
    &__block {
        background: $white;
        border: 1px solid $secondary-color-light;
        border-radius: .5rem;
        padding: 1.5rem 0;
        min-height: 7.5rem;
        transition: .3s;

        &:hover {
            background: $secondary-color-lighter;
        }
    }
    &__icon {
        margin: 0 auto 1rem;
        display: table;
    }
    &__title {
        color: $secondary-color;
        text-align: center;
        font-weight: map-get($fw, 600);
        display: block;
        font-size: map-get($fs,100);
    }
    &__notification {
        background: $primary-color;
        height: 1.55rem;
        width: 1.55rem;
        border-radius: 100%;
        position: absolute;
        right: -.75rem;
        top: -.65rem;
        line-height: 1.5rem;
        vertical-align: middle;
        color: $white;

        &__number {
            margin: 0 auto;
            display: table;
            text-align: center;
            font-size: map-get($fs,200);
            font-weight: map-get($fw , 400);
        }
    }
}