.order-table {
    width: 100%;
    min-width: 500px;

    @include media-breakpoint-up(xl) {
        width: 75%;
    }

    tr {
        line-height: 3rem;
        text-align: left;
        transition: .3s;

        td:nth-child(1) {
            padding-left: 1rem;
        }

        &:nth-child(even) {
            background: $white;
        }
        &:hover:not(:first-child) {
            background: $secondary-color-light;
        }
    }

    &--small {
        @include media-breakpoint-up(sm) {
            width: 50%;
            max-width: 25rem;
        }
        tr {
            &:nth-child(odd) {
                background: $white!important;
            }
            &:nth-child(even) {
                background: unset!important;
            }
        }
    }

    &--reverse-color {
        tr:nth-child(odd) {
            background: $white;
        }
        tr:nth-child(even) {
            background: unset;
        }
    }
}