.sidebar {
    &__nav {
        &__item {
            padding-left: 1rem;
            border-left: 2px solid $secondary-color-light;
        }
    }

    &-usps {
        margin-bottom: .75rem;

        &__title {
            font-size: map-get($fs,400);
        }
        &__text {
            font-size: map-get($fs,200);
            margin-left: .25rem;
            line-height: 1.5rem;
        }
        &__icon {
            svg {
                margin-top: .4375rem;
            }
        }
    }
}
