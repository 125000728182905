.footer {
    background: $secondary-color-lighter;

    .row + .row {
        margin-top: 2.5rem;
        border-top: 1px solid $secondary-color-light;
    }
    &__bottom-navigation-link {
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: auto;
        }
    }
}
