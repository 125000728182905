@import 'reboot';
@import 'base';
@import 'slick';
@import 'slick-lightbox';

// Dependencies
@import '~bootstrap/scss/bootstrap-grid';
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";

// Atoms
@import './atoms/btn';
@import './atoms/content';
@import './atoms/font';
@import './atoms/heading';
@import './atoms/img';
@import './atoms/input';
@import './atoms/link';
@import './atoms/price';
@import './atoms/text';
@import './atoms/line';
@import './atoms/number';
@import './atoms/breadcrumbs';
@import './atoms/alert';

// Components
@import './components/productblock';
@import './components/webshop-description';
@import './components/wishlistblock';
@import './components/dashboard';
@import './components/order-table';

// Organisms
@import './organisms/footer';
@import './organisms/header';
@import './organisms/searchbar';
@import './organisms/sidebar';
@import './organisms/nav';
@import './organisms/pagination';
@import './organisms/filters';
@import './organisms/microcart';
@import './organisms/section';

// Pages
@import 'page';

body {
    // sass-lint:disable no-vendor-prefixes
    -webkit-font-smoothing: antialiased;
    background: $secondary-color-lighter;
    color: $black;
    font-family: $font-family;

    // Add Bootstrap breakpoint to body
    &::after {
        content: 'xs';
        display: none;
    }

    @each $size in map-keys($container-max-widths) {
        @include media-breakpoint-up($size) {
            &::after {
                content: '#{$size}';
            }
        }
    }

    input[name="verify"] {
        display: none;
    }
}
