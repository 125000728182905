.wishlist {
    display: flex;
    align-items: stretch;

    @include media-breakpoint-up(sm) {
        &:nth-child(even) {
            padding-left: 1rem;
        }
        &:nth-child(odd) {
            padding-right: 1rem;
        }
    }

    &__new-wishlist {
        display: none;
    }
}