.link {
    cursor: pointer;
    display: inline-block;
    color: $secondary-color;
    font-family: $font-family;
    font-size: map-get($fs, 200);
    font-weight: map-get($fw, 600);
    line-height: 1.125rem;
    text-decoration: none;
    transition: .3s;

    &--text {
        color: $text-color;
    }

    &--text-light {
        color: $text-color-light!important;
    }

    &--fw-400 {
        font-weight: map-get($fw, 400);
    }

    &--fw-700 {
        font-weight: map-get($fw, 700);
    }

    &--primary {
        color: $primary-color;
    }

    &--no-underline {
        text-decoration: unset!important;
    }

    &--underline {
        text-decoration: underline!important;
        font-weight: map-get($fw, 400)!important;
        color: $text-color!important;
    }
    &:hover {
        color: $secondary-color!important;
    }
}
