.heading {
    font-family: $font-family;
    font-size: map-get($fs, 500);
    font-weight: map-get($fw, 700);

    @include media-breakpoint-up(lg) {
        font-size: map-get($fs, 600);
        font-weight: map-get($fw, 800);
    }

    &--300 {
        font-size: map-get($fs, 300);
        font-weight: map-get($fw, 700);
    }
    &--800 {
        font-size: map-get($fs, 800);
        font-weight: map-get($fw, 700);
    }
    &__title {
        font-size: map-get($fs, 800);
        font-weight: map-get($fw, 800);
    }
    &__subheading {
        color: $text-color-light;
        font-size: map-get($fs, 300);
    }

    &--small {
        font-size: map-get($fs, 300);
        font-weight: map-get($fw, 700);
    }
}

